import { useLocation, useNavigate } from "react-router-dom"
import { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import classNames from "classnames"
import { useTranslation } from "react-i18next"
import Typography from "../../../UIKit/base/Typography"
import { ArrowLeftIcon, CloseIcon, MenuIcon } from "../../../icons"
import { HeaderNav, UserNavigation } from "../Header"
import { lastNotificationsSelector } from "../../../../store/reducers/notifications"
import styles from "./Mobile.module.sass"

const MobileTitle = ({ title, showBackArrow = true }) => {
	const navigate = useNavigate()

	const location = useLocation()
	const path = location?.pathname

	const [showLeftMenu, setShowLeftMenu] = useState(false)
	const [modal, setModal] = useState(false)

	const notificationsData = useSelector(lastNotificationsSelector)
	const notifications = notificationsData?.notifications

	const ref = useRef(null)

	const unreadNotificationsCount = useMemo(() => {
		return notifications?.filter((item) => item.viewed === 0)?.length
	}, [notifications])

	const handleBack = () => {
		if (!showBackArrow) {
			return
		}

		if (window?.history?.length <= 1) {
			return navigate(`/project`)
		}
		return navigate(-1)
	}

	const handleCloseModal = () => {
		setModal(false)
	}

	const handleMenu = () => {
		setShowLeftMenu((p) => !p)
	}

	return (
		<>
			<div className={styles.mobileHeader__title}>
				{showBackArrow && (
					<div
						className={styles.mobileHeader__arrow}
						onClick={handleBack}
					>
						<ArrowLeftIcon />
					</div>
				)}

				{!showBackArrow && (
					<div className={styles.mobileHeader__menu}>
						<MenuIcon onClick={handleMenu} />
						{!!unreadNotificationsCount && (
							<div className={styles.mobileHeader__unread}>
								{unreadNotificationsCount}
							</div>
						)}
						{showLeftMenu && (
							<LeftMenu
								showLeftMenu={showLeftMenu}
								setShowLeftMenu={setShowLeftMenu}
								notificationsCount={unreadNotificationsCount}
							/>
						)}
					</div>
				)}

				<div className={styles.mobileHeader__text}>
					<Typography component="h3" fontVariant="regular">
						{title}
					</Typography>
				</div>

				<div className={styles.mobileHeader__buttons}>
					{/* 
          <div className={styles.mobileHeader__notificationButtonMobile}>
            <div
            onClick={() => setModal(true)}
              to="/notifications-mobile"
              className={classNames(
                styles.headerMobile__item,
                styles.mobileHeader__notifications,
                {
                  [styles.headerMobile__active]:
                    path === "/notifications-mobile",
                }
              )}
            >
              <NotificationIcon />
            </div>
          </div>

          <div className={styles.mobileHeader__notificationButtonTablet}>
            <NotificationsButton />
          </div>
*/}
					<UserNavigation />
				</div>
			</div>
		</>
	)
}

export default MobileTitle

function LeftMenu({
	targetWrapper,
	setShowLeftMenu,
	showLeftMenu,
	notificationsCount,
}) {
	const [closingAnimate, setClosingAnimate] = useState(false)

	const dispatch = useDispatch()
	const { pathname } = useLocation()

	const { t } = useTranslation()

	const close = useCallback(() => {
		setClosingAnimate(true)
		setTimeout(() => {
			setClosingAnimate(false)
			setShowLeftMenu(false)
		}, 0)
	}, [setShowLeftMenu, setClosingAnimate])

	// useClickOutside(targetWrapper, close);

	useEffect(() => {
		//close();
	}, [pathname, close])

	if (!showLeftMenu) {
		return null
	}

	return (
		<div
			className={classNames(styles.user__popower, {
				[styles.user__popower_close]: closingAnimate,
			})}
		>
			<div className={styles.user__popower_cancel} onClick={close}>
				<CloseIcon /> <Typography>{t("menu")}</Typography>
			</div>

			<HeaderNav
				baseClass="nav"
				type="mobile"
				notificationsCount={notificationsCount}
			/>
		</div>
	)
}
