import { createAction, handleActions } from "redux-actions"
import { all, put, call, takeLeading, take } from "redux-saga/effects"
import {
	domainThemeRequest,
	getTimezonesRequest,
	getLogoRequest,
	getCountriesRequest,
} from "../../api/domainTheme/domainTheme"
import { addGlobalLoadItem, removeGlobalLoadItem } from "./loader"
import { setAuthError } from "./auth"

const namespace = "domainTheme"

const GET_DOMAIN_THEME = `${namespace}/GET_DOMAIN_THEME`
const SET_DOMAIN_THEME = `${namespace}/SET_DOMAIN_THEME`
const SET_ERROR = `${namespace}/SET_ERROR`
const SET_NEGATE = `${namespace}/SET_NEGATE`

const SET_TIMEZONES = `${namespace}/SET_TIMEZONES`
const GET_TIMEZONES = `${namespace}/GET_TIMEZONES`

const SET_COUNTRIES = `${namespace}/SET_COUNTRIES`
const GET_COUNTRIES = `${namespace}/GET_COUNTRIES`

const GET_LOGO = `${namespace}/GET_LOGO`
const SET_LOGO = `${namespace}/SET_LOGO`
const RESET_DOMAIN_THEME = `${namespace}/RESET_DOMAIN_THEME`

const SET_ALLOWED_MODULES = `${namespace}/SET_ALLOWED_MODULES`

export const getDomainTheme = createAction(GET_DOMAIN_THEME)
export const setDomainTheme = createAction(SET_DOMAIN_THEME)
export const resetDomainTheme = createAction(RESET_DOMAIN_THEME)
const setTimezones = createAction(SET_TIMEZONES)
export const getTimezones = createAction(GET_TIMEZONES)
export const setError = createAction(SET_ERROR)
export const setNegate = createAction(SET_NEGATE)

const setLogo = createAction(SET_LOGO)
export const getLogo = createAction(GET_LOGO)

const setCountries = createAction(SET_COUNTRIES)
export const getCountries = createAction(GET_COUNTRIES)

const setAllowedModules = createAction(SET_ALLOWED_MODULES)

const initialState = {
	error: null,
	timezones: null,
	logo: null,
	countries: null,
	allowedModules: null,
}

export default handleActions(
	{
		[SET_DOMAIN_THEME]: (state, { payload }) => ({ ...state, ...payload }),
		[RESET_DOMAIN_THEME]: () => initialState,
		[SET_TIMEZONES]: (state, { payload }) => ({
			...state,
			timezones: payload,
		}),
		[SET_NEGATE]: (state, { payload }) => ({
			...state,
			theme: { ...state["theme"], negate: payload },
		}),
		[SET_ERROR]: (state, { payload }) => ({ ...state, error: payload }),
		[SET_LOGO]: (state, { payload }) => ({ ...state, logo: payload }),
		[SET_COUNTRIES]: (state, { payload }) => ({
			...state,
			countires: payload,
		}),
		// for the modules Kb->12,learning->13,surveys->14,performance->15
		[SET_ALLOWED_MODULES]: (state, { payload }) => ({
			...state,
			allowedModules: payload,
		}),
	},
	initialState
)

export const domainThemeSelector = (state) => state[namespace]
export const themeSelector = (state) => state[namespace].theme
export const timezonesSelector = (state) => state[namespace].timezones
export const fontSelector = (state) => state[namespace]?.theme?.font
export const negateSelector = (state) => state[namespace].theme.negate
export const titleSelector = (state) => state[namespace]?.theme?.title
export const errorSelector = (state) => state[namespace].error
export const logoSelector = (state) => state[namespace].logo
export const countiresSelector = (state) => state[namespace].countires

export const allowedModulesSelector = (state) => state[namespace].allowedModules

function* getThemeSaga() {
	yield put(addGlobalLoadItem(GET_DOMAIN_THEME))
	try {
		const {
			data: { theme },
		} = yield call(domainThemeRequest)

		const { theme_settings, modules, ...other } = theme

		const props = Object.keys(theme_settings)?.filter(Boolean) || []

		const newTheme = { ...other }

		if (props.length > 0) {
			props.forEach((p) => {
				newTheme[p] = theme_settings[p]
			})
		}

		yield put(setDomainTheme({ theme: newTheme }))
		yield put(setAllowedModules(modules?.map((m) => m.value)))
	} catch (err) {
		console.log(err)
		yield put(setAuthError(err?.response?.status))
	} finally {
		yield put(removeGlobalLoadItem(GET_DOMAIN_THEME))
	}
}

function* getTimezonesSaga() {
	while (true) {
		yield take(GET_TIMEZONES)
		yield put(addGlobalLoadItem(GET_TIMEZONES))
		try {
			const { data } = yield call(getTimezonesRequest)
			yield put(setTimezones(data.timezones))
		} catch (err) {
			console.log(err)
			yield put(setAuthError(err?.response?.status))
		} finally {
			yield put(removeGlobalLoadItem(GET_TIMEZONES))
		}
	}
}

function* getCountriesSaga() {
	while (true) {
		yield take(GET_COUNTRIES)
		yield put(addGlobalLoadItem(GET_COUNTRIES))
		try {
			const { data } = yield call(getCountriesRequest)
			yield put(setCountries(data.countries))
		} catch (err) {
			console.log(err)
		} finally {
			yield put(removeGlobalLoadItem(GET_COUNTRIES))
		}
	}
}

function* getLogoSaga() {
	while (true) {
		yield take(GET_LOGO)
		//yield put(addGlobalLoadItem(GET_TIMEZONES));
		try {
			const data = yield call(getLogoRequest)

			yield put(setLogo(data))
		} catch (err) {
			console.log(err)
		} finally {
			//yield put(removeGlobalLoadItem(GET_TIMEZONES));
		}
	}
}

export function* domainThemeSagas() {
	yield all([
		getTimezonesSaga(),
		takeLeading(GET_DOMAIN_THEME, getThemeSaga),
		getLogoSaga(),
		getCountriesSaga(),
	])
}
