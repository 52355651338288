import { useTranslation } from "react-i18next";
import classNames from "classnames";
import styles from "./product.module.scss";
import { formatDateNumber } from "../utils";
import Typography from "../UIKit/base/Typography";
import Status from "../Status/Status";

export default function ProductDetails({
  additionalData,
  status,
  lessonsCompleted,
  lessonsCount,
}) {
  const { t } = useTranslation();

  return (
    <div className={styles.courseInfo__details}>
      <div className={styles.courseInfo__block}>
        <Typography
          fontVariant="medium"
          className={styles.courseInfo__blockTitle}
        >
          {t("course_details")}
        </Typography>
      </div>
      <div>
        <div className={styles.courseInfo__detailsRow}>
          <Typography
            fontVariant="medium"
            className={classNames(styles.courseInfo__blockDesk)}
          >
            {t("status")}
          </Typography>
          <div className={styles.courseInfo__detailsStatus}>
            <Status className={styles.status}>{status}</Status>
          </div>
        </div>
        {status !== "completed" && additionalData?.due_date && (
          <div className={styles.courseInfo__detailsRow}>
            <Typography
              fontVariant="medium"
              className={classNames(styles.courseInfo__blockDesk)}
            >
              {t("time_left")}
            </Typography>
            <Typography
              fontVariant="medium"
              className={classNames(
                styles.courseInfo__blockDesk,
                styles.detail__data
              )}
            >
              {additionalData?.time_left}
            </Typography>
          </div>
        )}
        <div className={styles.courseInfo__detailsRow}>
          <Typography
            fontVariant="medium"
            className={classNames(styles.courseInfo__blockDesk)}
          >
            {t("lessons_completed")}
          </Typography>
          <Typography
            fontVariant="medium"
            className={classNames(
              styles.courseInfo__blockDesk,
              styles.detail__data
            )}
          >
            {lessonsCompleted}/{lessonsCount}
          </Typography>
        </div>
        {status !== "completed" && additionalData?.due_date && (
          <div className={styles.courseInfo__detailsRow}>
            <Typography
              fontVariant="medium"
              className={classNames(styles.courseInfo__blockDesk)}
            >
              {t("due_to")}
            </Typography>
            <Typography
              fontVariant="medium"
              className={classNames(
                styles.courseInfo__blockDesk,
                styles.detail__data
              )}
            >
              {formatDateNumber(additionalData?.due_date)}
            </Typography>
          </div>
        )}
        {status === "completed" && (
          <div className={styles.courseInfo__detailsRow}>
            <Typography
              fontVariant="medium"
              className={classNames(styles.courseInfo__blockDesk)}
            >
              {t("completion_date")}
            </Typography>
            <Typography
              fontVariant="medium"
              className={classNames(
                styles.courseInfo__blockDesk,
                styles.detail__data
              )}
            >
              {additionalData?.complete_learning_at &&
                formatDateNumber(additionalData?.complete_learning_at)}
            </Typography>
          </div>
        )}
      </div>
    </div>
  );
}
