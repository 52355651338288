import { useSelector } from "react-redux"
import { Link, useLocation } from "react-router-dom"
import classNames from "classnames"
import { breadcrumbsSelector } from "../../store/reducers/breadcrumbs"
import JustifyContainer from "../layouts/JustifyContainer"
import Typography from "../UIKit/base/Typography"
import styles from "./Breadcrumbs.module.sass"
import { globalLoadSelector } from "../../store/reducers/loader"
import PreloaderBreadcrumbs from "../Preloader/PreloaderTemplates/PreloaderBreadcrumbs"

export default function Breadcrumbs() {
	const breadcrumbs = useSelector(breadcrumbsSelector)
	let location = useLocation()
	const state = location?.state
	const load = useSelector(globalLoadSelector).loading

	if (breadcrumbs?.length <= 1) {
		return null
	}
	if (load) {
		return <div className={styles.loader}><PreloaderBreadcrumbs /></div>
	}
	return (
		<div className={styles.wrapper}>
			<JustifyContainer className={styles.container}>
				<ul className={styles.list}>
					{breadcrumbs.map((e, i) => (
						<Typography
							component="li"
							fontVariant="medium"
							key={e.path}
							className={classNames(styles.list__item, {
								[styles.ellipsis]: e?.ellipsis,
							})}
						>
							{i === breadcrumbs.length - 1 ? (
								e.title
							) : (
								<Link to={e.path} state={state}>
									{e.title}
								</Link>
							)}
						</Typography>
					))}
				</ul>
			</JustifyContainer>
		</div>
	)
}
