import { useSelector } from "react-redux"
import { Navigate, Outlet } from "react-router-dom"
import { allowedModulesSelector } from "../../store/reducers/domainTheme"

export default function AllowedModulesGuard({ moduleId }) {
	const allowedModules = useSelector(allowedModulesSelector)

	if (Array.isArray(allowedModules)) {
		const modules = new Set(allowedModules)
		const isAllowed = Array.isArray(moduleId)
			? moduleId.some((id) => modules.has(id))
			: modules.has(moduleId)

		if (isAllowed) {
			return <Outlet />
		}

		return <Navigate to="/" />
	}
	return null
}
