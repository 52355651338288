import classNames from "classnames"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { Link, useSearchParams } from "react-router-dom"
import { surveysSelector } from "../../store/reducers/surveys"
import { SortIcon } from "../icons"
import { localeFormat } from "../localeFormat"
import Typography from "../UIKit/base/Typography"
import styles from "./Surveys.module.sass"
import { tableHeaders } from "./utils"
import NoItems from "./NoItems"
import { globalLoadSelector, setShowLoader } from "../../store/reducers/loader"
import PreloaderList from "../Preloader/PreloaderTemplates/PreloaderList"
import MobileHeader from "../layouts/Header/Mobile/MobileHeader"

export default function Surveys() {
	const { t } = useTranslation()
	const surveys = useSelector(surveysSelector)
	const [query, setQuery] = useSearchParams()
	const dispatch = useDispatch()

	const loading = useSelector(globalLoadSelector).loading

	// const crumbs = useMemo(() => {
	// 	const arr = [
	// 		{ path: `/`, title: t("dashboard") },
	// 		{
	// 			path: `/surveys`,
	// 			title: t("scheduled_surveys"),
	// 		},
	// 	]

	// 	return arr
	// }, [t])

	// useEffect(() => {
	// 	!loading && dispatch(setBreadcrumbs(crumbs))
	// 	return () => {
	// 		dispatch(resetBreadcrumbs())
	// 	}
	// }, [crumbs, dispatch, loading])

	function handleThClick(e) {
		e.stopPropagation()
		const { currentTarget } = e
		const isAction = currentTarget.dataset.sort
		const name = currentTarget.dataset.name
		const sortValue = query.get(`sort[${name}]`)
		const sortItems = tableHeaders.filter((h) => h?.isSort)

		if (!!isAction) {
			sortItems.forEach((s) => {
				query.delete(`sort[${s.name}]`)
			})
			query.delete("page")
			if (!sortValue) {
				query.set(`sort[${name}]`, 1)
			}
			if (+sortValue === 1) {
				query.set(`sort[${name}]`, -1)
			} else {
				query.set(`sort[${name}]`, 1)
			}
			setQuery(query)
		}
	}

	if (loading) {
		return (
			<>
				{" "}
				<PreloaderList />
			</>
		)
	}

	return (
		<>
			<MobileHeader
				title={t("scheduled_surveys")}
				// breadcrumbsTitle={t("dashboard")}
				hideBreadcrumbs
				showBackArrow={false}
			/>
			<div className={styles.wrapper}>
				<Typography
					fontVariant="bold"
					className={styles.page__title}
					component={`h1`}
				>
					{t("scheduled_surveys")}
				</Typography>

				{!!surveys?.length && (
					<div className={styles.table}>
						<div className={styles.row}>
							{tableHeaders?.map((h, i) => (
								<div
									key={`${h?.title}-${i}`}
									className={classNames(styles.cell, styles.th, {
										[styles.th_sort]: h?.isSort,
										[styles.hideOnMobile]: h?.hideOnMobile
									})}
								>
									{h?.isSort && (
										<SortIcon
											onClick={handleThClick}
											data-name={h.name}
											data-sort={h.isSort ? "sort" : ""}
											className={classNames(
												styles.sort,
												{
													[styles.sort_ask]:
														+query.get(`sort[${h?.name}]`) === 1,
												},
												{
													[styles.sort_desk]:
														+query.get(`sort[${h?.name}]`) === -1,
												}
											)}
										/>
									)}
									<Typography className={styles.title}>
										{t(h?.title)}
									</Typography>
								</div>
							))}
						</div>
						{surveys?.map((s, i) => {
							let to = `/surveys/${s?.id}/questions/${s?.next_question_id}`
							if (s?.type === "manual") {
								to = `/surveys/${s?.id}/questions/${s?.next_question_id}/manual`
							}
							if (s?.type === "performance") {
								to = `/surveys/${s?.id}/questions/${s?.next_question_id}/performance/people/${s?.target_person?.id}`
							}
							return (
								<div key={`${s?.id}-${i}`} className={styles.row}>
									<Typography className={styles.cell}>
										<Link to={to}>{s?.title}</Link>
									</Typography>
									<Typography className={classNames(styles.cell, styles.hideOnMobile)}>
										{s?.target_person?.name || "-"}
									</Typography>
									<Typography className={styles.cell}>
										{s?.questions_count}
									</Typography>
									<Typography className={classNames(styles.cell, styles.hideOnMobile)}>
										{localeFormat(s?.start_date_at)}
									</Typography>
									<Typography className={classNames(styles.cell, styles.hideOnMobile)}>
										{localeFormat(s?.finish_date_at)}
									</Typography>
								</div>
							)
						})}
					</div>
				)}
				{!surveys?.length && <NoItems />}
			</div>
		</>
	)
}
