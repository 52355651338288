import { format } from "date-fns";
import { uk, ru, enUS as en, kk, it, es, pl } from "date-fns/locale";
export const LOCALE = { uk, ru, en, kk, it, es, pl };

const formatStr = (f) => {
  if (f === "time") {
    return `dd LLL yyyy, HH:mm`;
  }
  if (typeof f === "string") {
    return f;
  }
  return `dd LLL yyyy`;
};

export function localeFormat() {
  return format(
    new Date(arguments[0]),
    formatStr(arguments[1]),
    {
      awareOfUnicodeTokens: true,
      locale: LOCALE[localStorage.getItem("i18nextLng")],
    },
    ...arguments
  );
}
