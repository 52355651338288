import classNames from "classnames"
import React, { useCallback, useEffect, useRef, useState, useMemo } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { Link, useLocation } from "react-router-dom"
import { logout } from "../../../store/reducers/auth"
import {
	allowedModulesSelector,
	negateSelector,
	setNegate,
} from "../../../store/reducers/domainTheme"
import {
	getUser,
	menuSelector,
	personSelector,
} from "../../../store/reducers/user"
import Logo from "../../Logo/Logo"
import { NotificationsButton } from "../../Notifications/NotificationsButton"
import Avatar from "../../UIKit/avatarView/AvatarView"
import Typography from "../../UIKit/base/Typography"
import useClickOutside from "../../hooks/useClickOutside"
import {
	ArrowRightIcon,
	CloseIcon,
	TelegramIcon,
	TickIcon,
	ViberIcon,
	WhatsappIcon,
} from "../../icons"
import CustomLink from "../../link/CustomLink"
import JustifyContainer from "../JustifyContainer"
import styles from "./header.module.sass"
import { globalLoadSelector } from "../../../store/reducers/loader"
import PreloaderHeader from "../../Preloader/Preloaders/PreloaderHeader"
import DropdownNav from "./DropdownNav"

const Header = () => {
	const dispatch = useDispatch()

	const loading = useSelector(globalLoadSelector).loading

	useEffect(() => {
		dispatch(getUser())
	}, [dispatch])

	if (loading) {
		return <PreloaderHeader />
	}
	return (
		<>
			<header className={styles.header}>
				<JustifyContainer className={styles.header__container}>
					<div className={styles.header__left}>
						<Link className={styles.header__logo} to="/">
							<Logo isHeader />
						</Link>
						<HeaderNav baseClass="header" />
					</div>
					<div className={styles.header__right}>
						<div className={styles.header__right_icon}>
							<NotificationsButton />
							{/* <NegateChangeTheme /> */}
						</div>
						<UserNavigation />
					</div>
				</JustifyContainer>
			</header>
		</>
	)
}

export default Header

export function HeaderNav({ baseClass, type, notificationsCount }) {
	const { t } = useTranslation()
	const allowedModules = useSelector(allowedModulesSelector)
	const { pathname } = useLocation()

	const isPathActive = useCallback(
		(paths) => paths.some((path) => pathname.includes(path)),
		[pathname]
	)
	const learningActive = useMemo(() => isPathActive(["products", "catalog"]), [isPathActive])
	const moreActive = useMemo(() => isPathActive(["company-structure"]), [isPathActive])

	const learningList = [
		{
			link: "/products",
			name: t("my_courses")
		},
		{
			link: "/catalog",
			name: t("catalog")
		}
	]

	const moreList = [
		{
			link: "/company-structure",
			name: t("organization_structure_short")
		},
	]

	return (
		<div className={styles[`${baseClass}__center`]}>
			{/* {type === "mobile" && (
				<Typography
					component={CustomLink}
					fontVariant="medium"
					to="/"
					className={styles[`${baseClass}__link`]}
					activeClassName={styles[`${baseClass}__link_active`]}
				>
					<span>{t("home")}</span>
				</Typography>
			)} */}

			{/* MENU ITEMS START FIXME:*/}

			{allowedModules?.includes(12) ? (
				<Typography
					component={CustomLink}
					fontVariant="medium"
					to="/knowledge-base"
					className={styles[`${baseClass}__link`]}
					activeClassName={styles[`${baseClass}__link_active`]}
				>
					{t("knowledge_base")}
				</Typography>
			) : null}
			{allowedModules?.includes(13) ? (
				<DropdownNav 
					className={baseClass} 
					navTitle={t("learning")} 
					isActive={learningActive} 
					dropdownList={learningList}
				/>
			) : null}
			{allowedModules?.includes(14) || allowedModules?.includes(15) ? (
				<Typography
					component={CustomLink}
					fontVariant="medium"
					to="/surveys"
					className={styles[`${baseClass}__link`]}
					activeClassName={styles[`${baseClass}__link_active`]}
				>
					{t("surveys")}
				</Typography>
			) : null}
			<DropdownNav 
				className={baseClass} 
				navTitle={t("more")} 
				isActive={moreActive} 
				dropdownList={moreList}
			/>

			{/* MENU ITEMS END TODO:*/}

			{type === "mobile" && (
				<Typography
					component={CustomLink}
					fontVariant="medium"
					to="/notifications"
					className={classNames(
						styles[`${baseClass}__link`],
						styles[`${baseClass}__notifications`]
					)}
					activeClassName={styles[`${baseClass}__link_active`]}
				>
					<div>
						{" "}
						{t("notifications")}
						{!!notificationsCount && (
							<div className={styles[`${baseClass}__unread`]}>
								{notificationsCount}
							</div>
						)}
					</div>
				</Typography>
			)}
		</div>
	)
}
export function UserNavigation() {
	const person = useSelector(personSelector)
	const [menu, setMenu] = useState(false)
	const ref = useRef(null)

	function handleMenu(e) {
		setMenu(true)
	}
	if (!person) {
		return null
	}

	return (
		<div className={styles.user__wrapper} ref={ref}>
			<Avatar
				name={person.name}
				src={person.avatar?.small}
				textClassName={styles.avatar__text}
				imgClassName={styles.avatar}
				onClick={handleMenu}
			/>
			<UserMenu
				person={person}
				isOpenMenu={menu}
				setIsopenMenu={setMenu}
				targetWrapper={ref}
			/>
		</div>
	)
}

function UserMenu({ person, targetWrapper, setIsopenMenu, isOpenMenu }) {
	const [closingAnimate, setClosingAnimate] = useState(false)
	const [showBots, setShowBots] = useState(false)
	const [showThemes, setShowThemes] = useState(false)
	const dispatch = useDispatch()
	const { pathname } = useLocation()

	const menu = useSelector(menuSelector)

	const { t } = useTranslation()

	const close = useCallback(() => {
		setClosingAnimate(true)
		setTimeout(() => {
			setClosingAnimate(false)
			setIsopenMenu(false)
		}, 600)
	}, [setIsopenMenu, setClosingAnimate])

	useClickOutside(targetWrapper, close)

	useEffect(() => {
		close()
	}, [pathname, close])

	if (!isOpenMenu) {
		return null
	}

	return (
		<div
			className={classNames(styles.user__popower, {
				[styles.user__popower_close]: closingAnimate,
			})}
		>
			<div className={styles.user__popower_cancel} onClick={close}>
				<CloseIcon />
			</div>
			<div className={styles.user__popower_header}>
				<Avatar
					name={person.name}
					src={person?.avatar?.big}
					textClassName={classNames(
						styles.avatar__text,
						styles.avatar__text_big
					)}
					imgClassName={classNames(styles.avatar, styles.avatar_big)}
					Typography
					fontVariant="medium"
				/>
				<Typography fontVariant="medium" className={styles.user__name}>
					{person?.name}
				</Typography>
				<Typography fontVariant="medium" className={styles.user__email}>
					{person?.email}
				</Typography>
			</div>

			<ul className={styles.link__container}>
				{!!menu?.profile && (
					<li>
						<Typography
							component={Link}
							fontVariant=""
							className={styles.link}
							to="/profile"
						>
							{t("profile")}
						</Typography>
					</li>
				)}
				{!!menu?.bot_links && (
					<li
						className={styles.bots__wrapper}
						onClick={() => setShowBots((prev) => !prev)}
					>
						<div className={styles.bots__container}>
							<div
								className={classNames(
									styles.bots__container_connect,
									{
										[styles.bots__opened]: showBots,
									}
								)}
							>
								<Typography
									component="p"
									fontVariant=""
									className={styles.link}
								>
									{t("connect_messenger")}
								</Typography>
								<ArrowRightIcon />
							</div>
							{window.innerWidth > 768 && (
								<Bots bots={menu?.bot_links} />
							)}
							{window.innerWidth <= 768 && showBots && (
								<Bots bots={menu?.bot_links} />
							)}
						</div>
					</li>
				)}
				{!!menu?.support && (
					<li>
						<Typography
							component={Link}
							fontVariant=""
							className={styles.link}
							to="/support"
						>
							{t("faq")}
						</Typography>
					</li>
				)}

				<li
					className={styles.bots__wrapper}
					onClick={() => setShowThemes((prev) => !prev)}
				>
					<div className={styles.bots__container}>
						<div
							className={classNames(
								styles.bots__container_connect,
								{
									[styles.bots__opened]: showThemes,
								}
							)}
						>
							<Typography
								component="p"
								fontVariant=""
								className={styles.link}
							>
								{t("appearance")}
							</Typography>
							<ArrowRightIcon />
						</div>
						{window.innerWidth > 768 && <Themes />}
						{window.innerWidth <= 768 && showThemes && <Themes />}
					</div>
				</li>
				{!!menu?.logout && (
					<Typography
						component="li"
						fontVariant=""
						onClick={() => {
							dispatch(logout())
						}}
					>
						{t("log_out")}
					</Typography>
				)}
			</ul>
			<div className={styles.user__popower_negate}>
				{/*<NegateChangeTheme />*/}
			</div>
		</div>
	)
}

const Bots = ({ bots }) => {
	if (!bots || !Object.keys(bots)?.length) {
		return null
	}

	return (
		<div className={styles.bots}>
			<div className={styles.bots__list}>
				{Object.entries(bots).map(([key, value]) => (
					<Bot name={key} link={value} />
				))}
			</div>
		</div>
	)
}

const Bot = ({ name, link }) => {
	const { t } = useTranslation()
	return (
		<Link
			to={link}
			target="_blank"
			className={classNames(styles.bot, styles?.[name])}
		>
			{name === "telegram" && <TelegramIcon />}
			{name === "viber" && <ViberIcon />}
			{name === "whatsapp" && <WhatsappIcon />}
			<Typography>{t(name + "_bot")}</Typography>
		</Link>
	)
}

const Themes = () => {
	const { t } = useTranslation()

	const negate = useSelector(negateSelector)
	const dispatch = useDispatch()

	const negateSetDark = () => {
		dispatch(setNegate(true))
		localStorage.setItem("negate", true)
	}

	const negateSetLight = () => {
		dispatch(setNegate(false))
		localStorage.setItem("negate", false)
	}
	return (
		<div className={styles.themes}>
			<div className={styles.themes__list}>
				<div className={styles.themes__item} onClick={negateSetDark}>
					{negate && <TickIcon />}
					<Typography>{t("dark_mode")}</Typography>
				</div>
				<div className={styles.themes__item} onClick={negateSetLight}>
					{!negate && <TickIcon />}
					<Typography>{t("light_mode")}</Typography>
				</div>
			</div>
		</div>
	)
}
