import { useEffect, useMemo } from "react";
import ChartRadar, { Level } from "../ChartRadar/ChartRadar";
import styles from "./Skills.module.sass";
import { useDispatch, useSelector } from "react-redux";
import {
  resetBreadcrumbs,
  setBreadcrumbs,
} from "../../store/reducers/breadcrumbs";
import { useTranslation } from "react-i18next";
import Typography from "../UIKit/base/Typography";
import NoSkills from "./NoSkills";
import { getSkills, skillsSelector } from "../../store/reducers/user";
import MobileHeader from "../layouts/Header/Mobile/MobileHeader";
import useScrollTop from "../hooks/useScrollTop";
import classNames from "classnames";
import MobileTitle from "../layouts/Header/Mobile/MobileTitle";

const Skills = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const skills = useSelector(skillsSelector)?.skills?.[0];

  useScrollTop(skills);

  const crumbs = useMemo(() => {
    const arr = [{ path: "/", title: t("dashboard") }];

    arr.push({
      path: `/skills`,
      title: t("skills"),
    });

    return arr;
  }, [t]);

  useEffect(() => {
    dispatch(setBreadcrumbs(crumbs));
    return () => {
      dispatch(resetBreadcrumbs());
    };
  }, [crumbs, dispatch]);

  useEffect(() => {
    dispatch(getSkills());
  }, [dispatch]);

  return (
    <>
      <MobileHeader 
        title={t("skills")} 
        // breadcrumbsTitle={t("dashboard")} 
        showBackArrow={false}
        hideBreadcrumbs
      />

      <div className={styles.skills}>
        <Typography
          component="h1"
          fontVariant="bold"
          className={styles.skills__title}
        >
          {t("skills")}
        </Typography>
        {!!skills?.length && (
          <div className={styles.skills__container}>
            <div className={styles.skills__left}>
              <ChartRadar skills={skills} />
            </div>
            <div className={styles.skills__right}>
              <div className={styles.skills__list}>
                {window.innerWidth > 768 &&
                <div className={styles.skills__header}>
                  <div className={styles.skills__subject}>
                    <Typography> {t("skills")} </Typography>
                  </div>
                  <div className={styles.skills__level}>
                    <Typography> {t("level")} </Typography>
                  </div>
                </div>
                }

                {skills.map((i) => (
                  <div key={i.subject} className={classNames(styles.skills__item, {[styles.skills__item_mobile]: window.innerWidth <= 768})}>
                    <div className={styles.skills__subject}>
                      <Typography> {i.title} </Typography>
                    </div>
                    <div className={styles.skills__level}>
                      <Level level={i.mark} />
                      {window.innerWidth > 768 && <Typography> {i.level} </Typography> }
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}
        {!skills?.length && <NoSkills />}
      </div>
    </>
  );
};

export default Skills;
