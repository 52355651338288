import classNames from "classnames";
import i18next from "i18next";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import {
  certificatesSelector,
  generateCertificate,
} from "../../store/reducers/certificates";
import { errorSelector } from "../../store/reducers/course";
import { productSelector, resetProduct } from "../../store/reducers/products";
import ActionButton from "../ActionButton";
import { CourseCard } from "../Dashboard/Dashboard";
import Modules from "../Module/Modules";
import Percent from "../Percent/Percent";
import RequestAccessButton from "../RequestAccessButton";
import ReviewsSidebar from "../Reviews/ReviewsSidebar";
import Status from "../Status/Status";
import Typography from "../UIKit/base/Typography";
import CancelButton from "../UIKit/buttons/CancelButton/CancelButton";
import EditorView from "../UIKit/editor/view/EditorView";
import editorStyles from "../UIKit/editor/view/EditorView.module.sass";
import Modal from "../UIKit/modal";
import useScrollTop from "../hooks/useScrollTop";
import { BookIcon, LessonIcon, ModuleIcon } from "../icons";
import { formatEndings, formatDateNumber } from "../utils";
import MobileHeader from "./MobileHeader";
import Speakers from "./Speakers";
import ViewCourseImg from "./ViewCourseImg";
import styles from "./product.module.scss";
import { globalLoadSelector, setShowLoader } from "../../store/reducers/loader";
import PreloaderProduct from "../Preloader/Preloaders/PreloaderProduct";
import ProductDetails from "./ProductDetails";

const Product = () => {
  const error = useSelector(errorSelector);
  const { t } = useTranslation();
  const { productId } = useParams();
  const dispatch = useDispatch();

  const productData = useSelector(productSelector);
  const { product, courses } = productData; //course

  const location = useLocation()

  const [isBought, setIsBought] = useState(true);
  const [isRequested, setIsRequested] = useState(false);
  const [isAvailable, setIsAvailable] = useState(false);
  const [startSoon, setStartSoon] = useState(false);

  const certificates = useSelector(certificatesSelector);

  const certificate = useMemo(() => {
    // eslint-disable-next-line eqeqeq
    return certificates?.find((item) => item?.product_id == productId) || null;
  }, [certificates, productId, product?.id]);

  const loading = useSelector(globalLoadSelector).loading

  useEffect(() => {
    dispatch(setShowLoader(false))

    return () => {
      dispatch(setShowLoader(true))
    }
  }, [dispatch])

  useEffect(() => {
    if (
      !certificate &&
      product?.status === "completed" &&
      certificates?.length &&
      product?.certificate &&
      productId === product?.id
    ) {
      dispatch(generateCertificate({ productId: product?.id }));
    }
  }, [
    certificate,
    product?.status,
    dispatch,
    productId,
    certificates,
    product?.certificate,
    product?.id,
  ]);

  useEffect(() => {
    setIsBought(productData?.product?.bought);
    setIsRequested(!!productData?.product?.request_status);
    setIsAvailable(productData?.product?.available);
    setStartSoon(!!productData?.product?.start_days_left);
  }, [productData]);

  useEffect(() => {
    return () => resetProduct()
  }, [productId])

  useScrollTop(productData)

  if (loading) {
    return <PreloaderProduct type={product?.type} />
  }

  return (
    <>
      {product && (
        <div className={styles.product__inner}>
          {/* {window.innerWidth <= 1180 && (
            <MobileHeader
              courseTitle={product?.name}
              product={product}
              setIsBought={setIsBought}
              isBought={isBought}
            />
          )} */}

            <MobileHeader
              courseTitle={product?.name}
              product={product}
              setIsBought={setIsBought}
              isBought={isBought}
            />

          <Typography
            fontVariant="medium"
            className={styles.product__title_wrapper}
          >
            <div>
              <Typography fontVariant="bold" className={styles.product__title}>
                {product !== null ? product.name : ""}
              </Typography>
              <div className={styles.product__subtitle}>
                <Typography component="span" className={styles.product__type}>
                  {t(product?.type)}
                </Typography>

                {!!product.modules_count && (
                  <>
                    <ModuleIcon />
                    <Typography component="span" fontVariant="">
                      {formatEndings(
                        product !== null ? product.modules_count : 0,
                        "module",
                        i18next.language
                      )}
                    </Typography>
                  </>
                )}
                {!!product.posts_count && (
                  <>
                    <LessonIcon />
                    <Typography component="span" fontVariant="">
                      {formatEndings(
                        product?.posts_count ?? 0,
                        "lesson",
                        i18next.language
                      )}
                    </Typography>
                  </>
                )}

                {!!product.courses_count && (
                  <>
                    <BookIcon />
                    <Typography component="span" fontVariant="">
                      {formatEndings(
                        product?.courses_count ?? 0,
                        "course",
                        i18next.language
                      )}
                    </Typography>
                  </>
                )}
              </div>
            </div>
            <div className={styles.tabletMenu}>
              <MobileHeader
                courseTitle={product?.name}
                product={product}
                setIsBought={setIsBought}
                isBought={isBought}
                isTablet={true}
              />
            </div>
          </Typography>

          <div className={styles.grid}>
            <div>
              {!!product.modules_count && (
                <div className={`${styles.cards}`}>
                  <Modules state={location?.state} />
                </div>
              )}

              {!!product.courses_count && (
                <>
                  {!!courses?.length &&
                    courses.map((course) => (
                      <CourseCard
                        key={course?.id}
                        course={course}
                        isInPath={true}
                        pathId={product?.id}
                        pathName={product?.name}
                      />
                    ))}
                </>
              )}
            </div>
            {window.innerWidth >= 768 && <CardSmall />}
          </div>
        </div>
      )}
    </>
  );
};

export default Product;

export const CardSmall = () => {
  const course = useSelector(productSelector);
  const [showFullDesc, setShowFullDesc] = useState(false);
  const { t } = useTranslation();
  const { productId } = useParams();
  const dispatch = useDispatch();

  const location = useLocation()

  const allowReviews = course?.product?.allow_reviews;

  const { product, speakers, reviews, action_button } = course;

  const [showDescModal, setShowDescModal] = useState(false);

  const [isBought, setIsBought] = useState(true);
  const [isRequested, setIsRequested] = useState(false);
  const [isAvailable, setIsAvailable] = useState(false);
  const [startSoon, setStartSoon] = useState(false);

  const certificates = useSelector(certificatesSelector);

  const isMobile = window.innerWidth <= 768;

  const timeLeft = product?.finish_date
    ? Math.ceil(
        Math.abs(
          new Date(product?.finish_date).getTime() -
            new Date(product?.start_date).getTime()
        ) /
          (1000 * 3600 * 24)
      )
    : null;

  const certificate = useMemo(() => {
    // eslint-disable-next-line eqeqeq
    return certificates?.find((item) => item?.product_id == productId) || null;
  }, [certificates, productId]);

  useEffect(() => {
    if (
      !certificate &&
      product?.status === "completed" &&
      certificates?.length &&
      product?.certificate &&
      productId === product?.id
    ) {
      dispatch(generateCertificate({ productId: product?.id }));
    }
  }, [
    certificate,
    product?.status,
    dispatch,
    productId,
    certificates,
    product?.certificate,
  ]);

  useEffect(() => {
    setIsBought(course?.product?.bought);
    setIsRequested(!!course?.product?.request_status);
    setIsAvailable(course?.product?.available);
    setStartSoon(!!course?.product?.start_days_left);
  }, [course]);

  const changeDescView = () => {
    setShowFullDesc(!showFullDesc);
  };

  useScrollTop(course);

  const handleShowDescModal = () => {
    setShowDescModal(true);
  };

  const handleCloseDescModal = () => {
    setShowDescModal(false);
  };

  return (
    <>
      <div className={`${styles.cardsSmall}`}>
        <div className={classNames(styles.courseInfo, styles.cardsSmall_row)}>
          <div
            className={classNames(styles.courseInfo__img, {
              [styles.courseInfo__img_noBg]: !!product?.cover?.big,
            })}
          >
            <ViewCourseImg src={product?.cover?.big || null} />
          </div>
          {!isAvailable && !isRequested && !startSoon && (
            <RequestAccessButton setIsBought={setIsBought} />
          )}
          {!isBought &&
            !isAvailable &&
            isRequested &&
            !startSoon &&
            course?.product?.request_status === "requested" && (
              <Typography
                className={classNames(
                  styles.courseInfo__blockTitle,
                  styles.courseInfo__requestSend
                )}
              >
                {t("request_sent")}
              </Typography>
            )}
          {!isBought &&
            !isAvailable &&
            isRequested &&
            !startSoon &&
            course?.product?.request_status === "declined" && (
              <RequestAccessButton setIsBought={setIsBought} />
            )}

          {isBought && !isAvailable && startSoon && (
            <Typography
              className={classNames(
                styles.courseInfo__blockTitle,
                styles.courseInfo__startSoon
              )}
              fontVariant="semiBold"
            >
              {formatEndings(product?.start_days_left, "day", i18next.language)}{" "}
              {t("till_start")}
            </Typography>
          )}

          <div className={styles.courseInfo__sertif}>
            {isBought && isAvailable && (
              <>
                <ActionButton />
                {product !== null ? (
                  <div className={styles.procentOfCompleted}>
                    <Percent
                      info={{
                        radius: isMobile ? 12 : 16,
                        passingScore:
                          product.type === "path"
                            ? product.progress
                            : product.posts_completed,
                        passingTotal:
                          product.type === "path"
                            ? 100
                            : product.posts_count !== null
                            ? product.posts_count
                            : 1,
                      }}
                    />
                    <div className={styles.procentOfCompleted__text}>
                      {!!product.posts_count &&
                        Math.ceil(
                          (product.posts_completed * 100) / product.posts_count
                        )}
                      {product.progress}%
                    </div>
                  </div>
                ) : null}
              </>
            )}
          </div>
          <div className={styles.courseInfo__desk}>
            <div className={styles.courseInfo__block}>
              <Typography
                fontVariant="medium"
                className={styles.courseInfo__blockTitle}
              >
                {t(`about_${product.type}`)}
              </Typography>
              <Typography
                fontVariant="semiBold"
                className={styles.courseInfo__blockButton}
                onClick={changeDescView}
              >
                {showFullDesc ? t("collapse") : t("view-all")}
              </Typography>
              <Typography
                fontVariant="semiBold"
                className={styles.courseInfo__showDesc}
                onClick={handleShowDescModal}
              >
                {t("view-all")}
              </Typography>
            </div>
            <Typography
              fontVariant="medium"
              className={classNames(styles.courseInfo__blockDesk, {
                [styles.courseInfo__blockDesk_showAll]: !showFullDesc,
              })}
            >
              {product.description ? (
                <EditorView
                  content={product.description}
                  fontVariant=""
                  className={editorStyles.blockDesc}
                />
              ) : (
                "-"
              )}
            </Typography>
          </div>
        </div>
        {product.type === "course" && (
          <Speakers title="speakers" speakers={speakers} />
        )}

        {product.type === "course" && (
          <div className={styles.courseInfo__reviews}>
            <ReviewsSidebar
              title="Reviews"
              reviews={reviews}
              showReviewSend={
                isBought &&
                isAvailable &&
                course?.product?.status === "completed"
              }
              state={location?.state}
            />
          </div>
        )}
        {product.type === "course" && isBought && isAvailable && (
          <ProductDetails 
            additionalData={action_button?.additional_data} 
            status={product?.status}
            lessonsCount={product?.posts_count}
            lessonsCompleted={product?.posts_completed}
          />
        )}

        {product.type === "path" && isBought && isAvailable && (
          <div>
            <div className={styles.courseInfo__block}>
              <Typography
                fontVariant="medium"
                className={styles.courseInfo__blockTitle}
              >
                {t(`learning_path_details`)}
              </Typography>
            </div>
            <div className={styles.path__detail}>
              <Typography
                fontVariant="medium"
                className={classNames(styles.courseInfo__blockDesk)}
              >
                {" "}
                {t(`status`)}
              </Typography>
              <Status className={styles.status}>{product?.status}</Status>
            </div>

            {!!product?.days_left && product?.status !== "completed" && (
              <div className={styles.path__detail}>
                <Typography
                  fontVariant="medium"
                  className={classNames(styles.courseInfo__blockDesk)}
                >
                  {" "}
                  {t(`days_left`)}
                </Typography>

                <Typography
                  fontVariant="medium"
                  className={classNames(
                    styles.courseInfo__blockDesk,
                    styles.detail__data
                  )}
                >
                  {" "}
                  {product?.days_left}
                </Typography>
              </div>
            )}

            <div className={styles.path__detail}>
              <Typography
                fontVariant="medium"
                className={classNames(styles.courseInfo__blockDesk)}
              >
                {" "}
                {t(`courses_completed`)}
              </Typography>

              <Typography
                fontVariant="medium"
                className={classNames(
                  styles.courseInfo__blockDesk,
                  styles.detail__data
                )}
              >
                {" "}
                {`${product?.courses_completed_count}/${product?.courses_count}`}
              </Typography>
            </div>

            {!!product?.finish_date && product?.status !== "completed" && (
              <div className={styles.path__detail}>
                <Typography
                  fontVariant="medium"
                  className={classNames(styles.courseInfo__blockDesk)}
                >
                  {" "}
                  {t(`due_to`)}
                </Typography>
                <Typography
                  fontVariant="medium"
                  className={classNames(
                    styles.courseInfo__blockDesk,
                    styles.detail__data
                  )}
                >
                  {" "}
                  {formatDateNumber(product?.finish_date)}
                </Typography>
              </div>
            )}
            
            {product?.status === "completed" && (
              <div className={styles.path__detail}>
                <Typography
                  fontVariant="medium"
                  className={classNames(styles.courseInfo__blockDesk)}
                >
                  {t("completion_date")}
                </Typography>
                <Typography
                  fontVariant="medium"
                  className={classNames(
                    styles.courseInfo__blockDesk,
                    styles.detail__data
                  )}
                >
                  {formatDateNumber(product?.complete_learning_at)}
                </Typography>
              </div>
            )}
          </div>
        )}
      </div>
      <Modal
        isOpen={showDescModal}
        className={classNames(styles.modal, styles.modalDesc)}
        onClose={handleCloseDescModal}
      >
        {(close) => (
          <div className={styles.modal__inner}>
            <div className={styles.modal__header}>
              <Typography component="span" fontVariant="">
                {t(`about_${product.type}`)}
              </Typography>
              <CancelButton
                className={styles.modal__cancel}
                onClick={handleCloseDescModal}
              />
            </div>

            <div className={styles.courseInfo}>
              <div
                className={classNames(styles.courseInfo__img, {
                  [styles.courseInfo__img_noBg]: !!product?.cover?.big,
                })}
              >
                <ViewCourseImg src={product?.cover?.big || null} />
              </div>

              {!isBought && !isAvailable && !isRequested && !startSoon && (
                <RequestAccessButton setIsBought={setIsBought} />
              )}
              {!isBought &&
                !isAvailable &&
                isRequested &&
                !startSoon &&
                course?.product?.request_status === "requested" && (
                  <Typography
                    className={classNames(
                      styles.courseInfo__blockTitle,
                      styles.courseInfo__requestSend
                    )}
                  >
                    {t("request_sent")}
                  </Typography>
                )}
              {!isBought &&
                !isAvailable &&
                isRequested &&
                !startSoon &&
                course?.product?.request_status === "declined" && (
                  <RequestAccessButton setIsBought={setIsBought} />
                )}

              {isBought && !isAvailable && startSoon && (
                <Typography
                  className={classNames(
                    styles.courseInfo__blockTitle,
                    styles.courseInfo__startSoon
                  )}
                  fontVariant="semiBold"
                >
                  {formatEndings(
                    product?.start_days_left,
                    "day",
                    i18next.language
                  )}{" "}
                  {t("till_start")}
                </Typography>
              )}
              <div className={styles.courseInfo__desk}>
                <Typography
                  fontVariant=""
                  className={classNames(
                    styles.courseInfo__blockDesk,
                    styles.courseInfo__blockDesk_showAll,
                    styles.courseInfo__blockDesk_modal
                  )}
                >
                  {product.description ? (
                    <EditorView
                      content={product.description}
                      fontVariant=""
                      className={editorStyles.blockDesc}
                    />
                  ) : (
                    "-"
                  )}
                </Typography>
              </div>
            </div>
          </div>
        )}
      </Modal>
    </>
  );
};
