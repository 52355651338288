import styles from "./Mobile.module.sass"
import Breadcrumbs from "../../../Breadcrumbs/Breadcrumbs"
import MobileBreadcrumbs from "./MobileBreadcrumbs"
import MobileTitle from "./MobileTitle"

const MobileHeader = ({ title, breadcrumbsTitle, hideBreadcrumbs, showBackArrow }) => {
	return (
		<div className={styles.mobileHeader}>
			<MobileTitle title={title} showBackArrow={showBackArrow} />

			{hideBreadcrumbs ? null : (
				<>
					<div className={styles.mobileHeader__tabletBreadcrumbs}>
						<Breadcrumbs />
					</div>

					<div className={styles.mobileHeader__breadcrumbs}>
						<MobileBreadcrumbs title={breadcrumbsTitle} />
					</div>
				</>
			)}
		</div>
	)
}

export default MobileHeader
