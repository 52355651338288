import { useEffect, useMemo, useState } from "react";
import {
  certificatesSelector,
  downloadCertificate,
  getCertificates,
} from "../../store/reducers/certificates";
import { useSelector, useDispatch } from "react-redux";

import { useTranslation } from "react-i18next";
import styles from "./certificates.module.sass";

import style from "./certificates.module.sass";
import { DownloadIcon, CloseIcon } from "../icons";
import { personSelector } from "../../store/reducers/user";
import { productsSelector, getProducts } from "../../store/reducers/products";
import { themeSelector } from "../../store/reducers/domainTheme";
import NoItems from "./NoItems";
import {
  resetBreadcrumbs,
  setBreadcrumbs,
} from "../../store/reducers/breadcrumbs";
import Typography from "../UIKit/base/Typography";
import MobileHeader from "../layouts/Header/Mobile/MobileHeader";
import { globalLoadSelector, setShowLoader } from "../../store/reducers/loader";
import PreloaderSupport from "../Preloader/Preloaders/PreloaderFaq";
import PreloaderCertificates from "../Preloader/Preloaders/PreloaderCertificates";

const Certificates = () => {
  const certificates = useSelector(certificatesSelector);
  const person = useSelector(personSelector);
  const theme = useSelector(themeSelector);
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const loading = useSelector(globalLoadSelector).loading

	useEffect(() => {
		dispatch(setShowLoader(false))

		return () => {
			dispatch(setShowLoader(true))
		}
	}, [dispatch])
 
 

  const person_name = person?.name;
  const project_name = theme?.title;

  const crumbs = useMemo(() => {
    const arr = [
      { path: `/`, title: t("dashboard") },
      {
        path: `/certificates`,
        title: t("certificates"),
      },
    ];

    return arr;
  }, [t]);

  useEffect(() => {
    dispatch(getCertificates());
  }, [dispatch]);

  useEffect(() => {
    !loading && dispatch(setBreadcrumbs(crumbs));
    return () => {
      dispatch(resetBreadcrumbs());
    };
  }, [crumbs, dispatch, loading]);

  if(loading){
		return (<> 
			 <PreloaderCertificates />
		</>)
	  }


  if (!certificates?.length) {
    return (
      <div className={styles.certificates__container}>
      <MobileHeader
        title={t("certificates")}
        // breadcrumbsTitle={t("dashboard")}
        hideBreadcrumbs={true}
        showBackArrow={false}
      />

      <div className={styles.certificates__noItems}>
        <Typography
          fontVariant="semiBold"
          className={styles.title}
          component={`h1`}
        >
          {t("certificates")}
        </Typography>
        <NoItems />
      </div>
      </div>
    );
  }
  return (
    <div className={styles.certificates__container}>
      <MobileHeader
        title={t("certificates")}
        // breadcrumbsTitle={t("dashboard")}
        showBackArrow={false}
        hideBreadcrumbs
      />
      <div className={styles.wrapper}>
        <Typography
          fontVariant="semiBold"
          className={styles.title}
          component={`h1`}
        >
          {t("certificates")}
        </Typography>
        {!!certificates?.length && (
          <div className={style.certificates}>
            {certificates.map((certificate) => (
              <CertificateItem
                key={certificate?.id}
                certificate={certificate}
                person_name={person_name}
                project_name={project_name}
              />
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default Certificates;

const CertificateItem = ({ certificate, products }) => {
  const [showLightbox, setShowLightbox] = useState(false);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  function handleDownload() {
    dispatch(
      downloadCertificate({
        certificateId: certificate?.id,
      })
    );
  }

  function handleShowLighbox() {
    setShowLightbox(true);
  }

  function handleCloseLighbox() {
    setShowLightbox(false);
  }

  if (!certificate) {
    return null;
  }
  return (
    <>
      <div key={certificate.id} className={style.certificateItem}>
        <div className={style.certificateItem__image}>
          <img
            src={certificate.image?.origin}
            alt="certificate"
            onClick={handleShowLighbox}
          />
        </div>

        <div className={style.certificateItem__data}>
          <div className={style.certificateItem__title}>
            {t("certificate_text", {
              course_name: certificate?.product_title,
            })}
          </div>
          <div className={style.certificateItem__download}>
            <button
              className={styles.download}
              onClick={handleDownload}
              type="button"
            >
              <DownloadIcon />
            </button>
          </div>
        </div>

        {showLightbox && (
          <div
            className={style.certificateItem__lightbox}
            onClick={handleCloseLighbox}
          >
            <div className={style.certificateItem__lightbox_inner}>
              <div
                className={style.certificateItem__lightbox_close}
                onClick={handleCloseLighbox}
              >
                <CloseIcon />
              </div>
              <img src={certificate.image?.origin} alt="certificate" />
            </div>
          </div>
        )}
      </div>
    </>
  );
};
