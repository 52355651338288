import { Suspense, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import Color from "color"
import BasicRouter from "./components/routes/index"
import Alert from "./components/UIKit/alert"
import Preloader from "./components/Preloader/Prepoader"
import {
	getCountries,
	getDomainTheme,
	getLogo,
	getTimezones,
	themeSelector,
} from "./store/reducers/domainTheme"
import Head from "./components/Head"
import { isContrastingColor } from "./components/utils"
import { FormikValueObserverProvider } from "./components/forms/FormikValueObserverControl/FormikValueObserverProvider"
import { RouterProvider } from "react-router-dom"

const App = () => {
	const dispatch = useDispatch()
	const theme = useSelector(themeSelector)

	useEffect(() => {
		dispatch(getDomainTheme())
		dispatch(getTimezones())
		dispatch(getCountries())
		dispatch(getLogo())
	}, [dispatch])

	useEffect(() => {
		if (theme) {
			let { primary_color } = theme

			if (!primary_color) {
				primary_color = "#6B4DFF"
			}

			const lightTheme = !theme.negate
			const black = "#000000"
			const white = "#ffffff"
			const neutral = "#798086"

			const blue = "#17B4E5"
			const green = "#00CC66"
			const yellow = "#FFBB33"
			const orange = "#FF6600"
			const red = "#FF4E6B"

			//primitives
			const brand500 = Color(primary_color)

			//alpha(0.2)
			const brand100 = Color(black).mix(brand500, 0.16).hex()
			const brand200 = Color(black).mix(brand500, 0.3).hex()
			const brand300 = Color(black).mix(brand500, 0.6).hex()
			const brand400 = Color(black).mix(brand500, 0.8).hex()
			const brand600 = Color(white).mix(brand500, 0.7).hex()
			const brand700 = Color(white).mix(brand500, 0.4).hex()
			const brand800 = Color(white).mix(brand500, 0.2).hex()
			const brand900 = Color(white).mix(brand500, 0.04).hex()

			const skeletonColor = brand800

			const neutral500 = Color(neutral)
			const neutral0 = black

			const neutral100 = "#121314"
			const neutral200 = Color(black).mix(neutral500, 0.3).hex()
			const neutral300 = Color(black).mix(neutral500, 0.6).hex()
			const neutral400 = Color(black).mix(neutral500, 0.8).hex()
			const neutral600 = Color(white).mix(neutral500, 0.7).hex()
			const neutral700 = Color(white).mix(neutral500, 0.4).hex()
			const neutral800 = Color(white).mix(neutral500, 0.2).hex()
			const neutral900 = "#F8F9F9"
			const neutral1000 = white

			const blue500 = Color(blue)
			const blue600 = Color(white).mix(blue500, 0.7).hex()
			const green500 = Color(green)
			const green600 = Color(white).mix(green500, 0.7).hex()
			const yellow500 = Color(yellow)
			const yellow600 = Color(white).mix(yellow500, 0.7).hex()
			const orange500 = Color(orange)
			const orange600 = Color(white).mix(orange500, 0.7).hex()
			const red500 = Color(red)
			const red600 = Color(white).mix(red500, 0.7).hex()

			//contrast
			const backgroundMain = lightTheme ? neutral1000 : neutral200
			const lowContrast = !isContrastingColor(
				brand500.hex(),
				backgroundMain
			)

			//variables
			//surface
			const surfaceBrand = lightTheme ? brand500 : brand600
			const surfacePrimary = lightTheme ? neutral1000 : neutral200
			const surfaceSecondary = lightTheme
				? !lowContrast
					? brand900
					: neutral900
				: neutral100
			const surfaceTertiary = lightTheme
				? !lowContrast
					? brand800
					: neutral800
				: neutral300
			const surfaceContrast = lightTheme ? neutral600 : neutral500
			const surfaceHightContrast = lightTheme ? neutral400 : neutral700
			const surfaceInvert = lightTheme ? neutral0 : neutral1000
			const surfaceAi = lightTheme ? blue500 : blue600
			const surfacePositive = lightTheme ? green500 : green600
			const surfaceWarning = lightTheme ? yellow500 : yellow600
			const surfaceLimit = lightTheme ? orange500 : orange600
			const surfaceNegative = lightTheme ? red500 : red600

			// text
			const textBrand = !lowContrast ? surfaceBrand : surfaceInvert
			const textPrimary = surfaceInvert
			const textSecondary = surfaceHightContrast
			const textTertiary = surfaceContrast
			const textInvert = surfacePrimary
			const textButton = lightTheme
				? !lowContrast
					? neutral1000
					: neutral0
				: !lowContrast
				? neutral0
				: neutral1000
			const textBadges = neutral0
			const textAi = surfaceAi
			const textPositive = surfacePositive
			const textWarning = surfaceWarning
			const textLimit = surfaceLimit
			const textNegative = surfaceNegative

			//borders
			const borderBrand = !lowContrast ? surfaceBrand : surfaceContrast
			const borderBrandSecondary = surfaceTertiary
			const borderBrandTertiary = surfaceSecondary
			const borderPrimary = surfacePrimary
			const borderSecondary = surfaceContrast
			const borderTertiary = surfaceHightContrast
			const borderInvert = surfaceInvert
			const borderAi = surfaceAi
			const borderPositive = surfacePositive
			const borderWarning = surfaceWarning
			const borderLimit = surfaceLimit
			const borderNegative = surfaceNegative

			// icons
			const iconBrand = textBrand
			const iconPrimary = textPrimary
			const iconSecondary = textSecondary
			const iconTertiary = textTertiary
			const iconInvert = textInvert
			const iconButton = lightTheme
				? !lowContrast
					? neutral1000
					: neutral0
				: !lowContrast
				? neutral0
				: neutral1000
			const iconAi = textAi
			const iconPositive = textPositive
			const iconWarning = textWarning
			const iconLimit = textLimit
			const iconNegative = textNegative

			//brand button
			const buttonBrandDisabledAll = !lowContrast
				? neutral800
				: neutral300
			const buttonBrandEnabled = brand500
			const buttonBrandHovered = brand600
			const buttonBrandFocused = brand600
			const buttonBrandPressed = brand700
			const buttonBrandSecondaryEnabled = lightTheme
				? !lowContrast
					? brand900
					: neutral900
				: !lowContrast
				? brand200
				: neutral300
			const buttonBrandSecondaryHovered = lightTheme
				? !lowContrast
					? brand800
					: neutral800
				: !lowContrast
				? brand300
				: neutral400
			const buttonBrandSecondaryFocused = lightTheme
				? !lowContrast
					? brand800
					: neutral800
				: !lowContrast
				? brand300
				: neutral400
			const buttonBrandSecondaryPressed = lightTheme
				? !lowContrast
					? brand700
					: neutral700
				: !lowContrast
				? brand400
				: neutral500

			//button Neutral
			const buttonNeutralEnabled = lightTheme ? neutral200 : neutral700
			const buttonNeutralHovered = lightTheme ? neutral300 : neutral800
			const buttonNeutralFocused = lightTheme ? neutral300 : neutral800
			const buttonNeutralPressed = lightTheme ? neutral400 : neutral900

			const buttonNeutralSecondaryEnabled = lightTheme
				? neutral800
				: neutral300
			const buttonNeutralSecondaryHovered = lightTheme
				? neutral700
				: neutral400
			const buttonNeutralSecondaryFocused = lightTheme
				? neutral700
				: neutral400
			const buttonNeutralSecondaryPressed = lightTheme
				? neutral600
				: neutral500

			const buttonNeutralTertiaryEnabled = lightTheme
				? neutral1000
				: neutral200
			const buttonNeutralTertiaryHovered = lightTheme
				? neutral900
				: neutral300
			const buttonNeutralTertiaryFocused = lightTheme
				? neutral900
				: neutral300
			const buttonNeutralTertiaryPressed = lightTheme
				? neutral800
				: neutral400

			//const buttonAiSecondaryEnabled = lightTheme ? blue900 : blue200
			//const buttonAiSecondaryHovered = lightTheme ? blue800 : blue300
			//const buttonAiSecondaryFocused = lightTheme ? blue800 : blue300
			//const buttonAiSecondaryPressed = lightTheme ? blue700 : blue400

			//elevation (shadow)
			const elevationNone = Color(neutral0).alpha(0).hex()
			const elevation1 = lightTheme
				? Color(neutral0).alpha(0.04).hexa()
				: Color(neutral0).alpha(0.2).hexa()
			const elevation2 = lightTheme
				? Color(neutral0).alpha(0.04).hexa()
				: Color(neutral0).alpha(0.2).hexa()
			const elevation3 = lightTheme
				? Color(neutral0).alpha(0.08).hexa()
				: Color(neutral0).alpha(0.4).hexa()
			const elevation4 = lightTheme
				? Color(neutral0).alpha(0.12).hexa()
				: Color(neutral0).alpha(0.6).hexa()
			const elevation5 = lightTheme
				? Color(neutral0).alpha(0.16).hexa()
				: Color(neutral0).alpha(0.8).hexa()

			////old
			const bg_color = !lightTheme
				? Color(theme.bg_color).negate().hex()
				: theme.bg_color
			const text_color = !lightTheme
				? Color(theme.text_color).negate().hex()
				: theme.text_color

			const secondaryDarkGray = Color(white)
				.mix(Color(bg_color))
				.mix(Color(text_color), 0.72)
				.hex()
			const secondaryGray = Color(white)
				.mix(Color(bg_color))
				.mix(Color(text_color), 0.4)
				.hex()
			const secondaryLightPurple = Color(white)
				.mix(Color(bg_color))
				.mix(Color(primary_color), 0.16)
				.hex()
			const secondaryBackground = Color(white)
				.mix(Color(bg_color))
				.mix(Color(primary_color), 0.04)
				.hex()

			const primaryHoverColor = Color(white)
				.mix(Color(bg_color), 0.24)
				.mix(Color(primary_color))
				.hex()
			const primaryActiveColor = Color(black)
				.mix(Color(text_color), 0.24)
				.mix(Color(primary_color))
				.hex()

			const shadowColor = Color(primary_color).alpha(0.04).hexa()
			const strongShadowColor = Color(primary_color).alpha(0.12).hexa()
			const primary_color40percent = Color(primary_color)
				.alpha(0.4)
				.hexa()

			const { body } = document
			body.style = `
			--primary_color: ${primary_color};
			--bg_color: ${bg_color};
			--text_color: ${text_color};
			--secondaryDarkGray: ${secondaryDarkGray};
			--secondaryGray: ${secondaryGray};
			--secondaryLightPurple: ${secondaryLightPurple};
			--secondaryBackground: ${secondaryBackground};
			--primaryHoverColor: ${primaryHoverColor};
			--primaryActiveColor: ${primaryActiveColor};
			--shadowColor: ${shadowColor};
			--strongShadowColor: ${strongShadowColor};
			--primary_color40percent: ${primary_color40percent}; 
 
			--surfaceBrand: ${surfaceBrand}; 
			--surfacePrimary: ${surfacePrimary}; 
			--surfaceSecondary: ${surfaceSecondary};  
			--surfaceTertiary: ${surfaceTertiary}; 
			--surfaceContrast: ${surfaceContrast}; 
			--surfaceHightContrast: ${surfaceHightContrast}; 
			--surfaceInvert: ${surfaceInvert}; 
			--surfaceAi: ${surfaceAi};  
			--surfacePositive: ${surfacePositive};
			--surfaceWarning: ${surfaceWarning}; 
			--surfaceLimit: ${surfaceLimit};  
			--surfaceNegative: ${surfaceNegative};

			--textBrand: ${textBrand}; 
			--textPrimary: ${textPrimary}; 
			--textSecondary: ${textSecondary};  
			--textTertiary: ${textTertiary};  
			--textInvert: ${textInvert}; 
			--textButton: ${textButton};  
			--textBadges: ${textBadges}; 
			--textAi: ${textAi};  
			--textPositive: ${textPositive};
			--textWarning: ${textWarning}; 
			--textLimit: ${textLimit};  
			--textNegative: ${textNegative};

			--borderBrand: ${borderBrand};  
			--borderBrandSecondary: ${borderBrandSecondary};
			--borderBrandTertiary: ${borderBrandTertiary};  
			--borderPrimary: ${borderPrimary}; 
			--borderSecondary: ${borderSecondary}; 
			--borderTertiary: ${borderTertiary}; 
			--borderInvert: ${borderInvert}; 
			--borderAi: ${borderAi};  
			--borderPositive: ${borderPositive};
			--borderWarning: ${borderWarning}; 
			--borderLimit: ${borderLimit};  
			--borderNegative: ${borderNegative};

			--iconBrand: ${iconBrand}; 
			--iconPrimary: ${iconPrimary}; 
			--iconSecondary: ${iconSecondary};  
			--iconTertiary: ${iconTertiary};  
			--iconInvert: ${iconInvert}; 
			--iconButton: ${iconButton};   
			--iconAi: ${iconAi};  
			--iconPositive: ${iconPositive}; 
			--iconWarning: ${iconWarning}; 
			--iconLimit: ${iconLimit};  
			--iconNegative: ${iconNegative};
						
			--buttonBrandDisabledAll: ${buttonBrandDisabledAll};
			--buttonBrandEnabled: ${buttonBrandEnabled}; 
			--buttonBrandHovered: ${buttonBrandHovered}; 
			--buttonBrandFocused: ${buttonBrandFocused}; 
			--buttonBrandPressed: ${buttonBrandPressed};
			--buttonBrandSecondaryEnabled: ${buttonBrandSecondaryEnabled}; 
			--buttonBrandSecondaryHovered: ${buttonBrandSecondaryHovered}; 
			--buttonBrandSecondaryFocused: ${buttonBrandSecondaryFocused};  
			--buttonBrandSecondaryPressed: ${buttonBrandSecondaryPressed}; 

			--buttonNeutralEnabled: ${buttonNeutralEnabled}; 
			--buttonNeutralHovered: ${buttonNeutralHovered}; 
			--buttonNeutralFocused: ${buttonNeutralFocused}; 
			--buttonNeutralPressed: ${buttonNeutralPressed};
			--buttonNeutralSecondaryEnabled: ${buttonNeutralSecondaryEnabled}; 
			--buttonNeutralSecondaryHovered: ${buttonNeutralSecondaryHovered}; 
			--buttonNeutralSecondaryFocused: ${buttonNeutralSecondaryFocused};  
			--buttonNeutralSecondaryPressed: ${buttonNeutralSecondaryPressed}; 
			--buttonNeutralTertiaryEnabled: ${buttonNeutralTertiaryEnabled}; 
			--buttonNeutralTertiaryHovered: ${buttonNeutralTertiaryHovered}; 
			--buttonNeutralTertiaryFocused: ${buttonNeutralTertiaryFocused}; 
			--buttonNeutralTertiaryPressed: ${buttonNeutralTertiaryPressed};

			--elevation1: ${elevation1};
			--elevation2: ${elevation2}; 
			--elevation3: ${elevation3}; 
			--elevation4: ${elevation4}; 
			--elevation5: ${elevation5};

			--skeletonColor: ${skeletonColor}
			`
		}
	}, [theme])

	return (
		<>
			<Suspense fallback={<Preloader />}>
				<Head />
				<Alert />
				<Preloader />
				<FormikValueObserverProvider>
					{/* <Page404> */}
					<RouterProvider
						router={BasicRouter}
						future={{
							v7_startTransition: true,
							v7_relativeSplatPath: true,
						}}
					/>
					{/* </Page404> */}
				</FormikValueObserverProvider>
			</Suspense>
		</>
	)
}

export default App
