import { useState, useEffect, useContext } from "react"
import i18next from "i18next"
import Typography from "../UIKit/base/Typography"
import style from "./style.module.sass"
import { themeSelector } from "../../store/reducers/domainTheme"
import { useSelector } from "react-redux"
import { AuthLayoutctx } from "../layouts/AuthLayout"
import classNames from "classnames"

const langs = [
	{ value: "ru", label: "ru" },
	{ value: "en", label: "en" },
	{ value: "uk", label: "ua" },
	{ value: "it", label: "it" },
	{ value: "es", label: "es" },
	{ value: "pl", label: "pl" },
]

const LangSwitcher = () => {
  const [showSubMenu, setShowSubmenu] = useState(false);
  const { currentLang, setCurrentLang } = useContext(AuthLayoutctx);
  const theme = useSelector(themeSelector);
  const language = theme?.language;

  useEffect(() => {
    if (language) {
      i18next.changeLanguage(language);
      setCurrentLang(language);
    }
  }, [language, setCurrentLang]);

  const handleClick = (e) => {
    const lang = e.target.getAttribute("data-lang");
    i18next.changeLanguage(lang);
    setCurrentLang(lang);
    setShowSubmenu(false);
  };

  const openSubmenu = () => {
    setShowSubmenu(!showSubMenu);
  };

  const closeSubmenu = () => {
    setShowSubmenu(false);
  };

	return (
		<>
			<div className={style.lang__switch}>
				<div
					className={classNames(style.active, {
						[style.active__pressed]: showSubMenu,
					})}
					onClick={openSubmenu}
				>
					<Typography component="span">
						{langs.find((i) => i.value === currentLang)?.label}
					</Typography>
				</div>

				{showSubMenu && (
					<>
						<div className={style.submenu}>
							{langs.map((lang) => (
								<Typography
									component="div"
									key={lang.value}
									className={`${style.submenu__item} ${
										lang.value === currentLang &&
										style.submenu__firstItem
									}`}
									data-lang={lang.value}
									onClick={handleClick}
								>
									{lang.label}
								</Typography>
							))}
						</div>
						<div
							className={style.overlay}
							onClick={closeSubmenu}
						></div>
					</>
				)}
			</div>
		</>
	)
}

export default LangSwitcher;
