import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { coursesSelector, getCourses } from "../../store/reducers/catalog";
import MobileHeader from "../layouts/Header/Mobile/MobileHeader";
import ProductList from "../ProductList";
import NoItems from "../ProductList/NoItems";
import tabStyles from "../Products/products.module.sass";
import Typography from "../UIKit/base/Typography";
import { createTabs } from "../UIKit/tabs/tabs";
import useScrollTop from "../hooks/useScrollTop";
import styles from "./Catalog.module.sass";
import PreloaderSupport from "../Preloader/Preloaders/PreloaderFaq";
import { globalLoadSelector, setShowLoader } from "../../store/reducers/loader";
import PreloaderProducts from "../Preloader/Preloaders/PreloaderProducts";

const Catalog = () => {
  const { t } = useTranslation();
  const courses = useSelector(coursesSelector);

  let [searchParams] = useSearchParams();
  const type = searchParams.get("type") || "";

  const dispatch = useDispatch();

  const loading = useSelector(globalLoadSelector).loading

	useEffect(() => {
		dispatch(setShowLoader(false))

		return () => {
			dispatch(setShowLoader(true))
		}
	}, [dispatch])
 

  useScrollTop(searchParams);

  useEffect(() => {
    dispatch(getCourses({ type }));
  }, [searchParams, type, dispatch]);

  function viewTabs() {
    return (
      <>
        <Typography
          className={styles.catalog__title}
          fontVariant="bold"
          component="h2"
        >
          {t("catalog")}
        </Typography>
        <div className={tabStyles.line}>
          <div className={tabStyles.tabLine}>
            {createTabs({
              text: t("all"),
              url: `/catalog`,
              style: {
                color: !type ? "active" : "secondaryDarkGray",
              },
            })}
            {createTabs({
              text: t("courses"),
              url: `/catalog?type=course`,
              style: {
                color: type === "course" ? "active" : "secondaryDarkGray",
              },
            })}
            {createTabs({
              text: t("paths"),
              url: `/catalog?type=path`,
              style: {
                color: type === "path" ? "active" : "secondaryDarkGray",
              },
            })}
          </div>
        </div>
      </>
    );
  }

	if(loading){
		return (<PreloaderProducts />)
	  }

  return (
    <>
      <MobileHeader
				title={t("catalog")}
				hideBreadcrumbs
				showBackArrow={false}
			/>
      {viewTabs()}

      {!courses?.length && <NoItems />}
      <div className={styles.products}>
        <ProductList products={courses} type="catalog" />
      </div>
    </>
  );
};

export default Catalog;
