import { createContext, useCallback, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"

export const FormikValueObserverCtx = createContext()

export const FormikValueObserverProvider = ({ children }) => {
	const [blocker, setBlocker] = useState(null)

	const { t } = useTranslation()

	const handleBlocker = useCallback((props) => setBlocker(props), [])

	useEffect(() => {
		if (blocker?.state === "blocked") {
			const confirm = window.confirm(t("сhanges_not_be_saved"))
			if (confirm && blocker) {
				blocker.proceed()
				setBlocker(null)
			} else {
				blocker.reset()
			}
		}
	}, [blocker, t])

	return (
		<FormikValueObserverCtx.Provider value={{ setBlocker: handleBlocker }}>
			{children}
		</FormikValueObserverCtx.Provider>
	)
}
