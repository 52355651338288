import classNames from "classnames"
import React, { useState } from "react"
import Typography from "../../UIKit/base/Typography"
import { ArrowDownIcon, ArrowUpIcon } from "../../icons"
import CustomLink from "../../link/CustomLink"
import styles from "./header.module.sass"

const DropdownNav = ({ className, type, navTitle, isActive, dropdownList }) => {
	const [show, setShow] = useState(false)

	return (
		<div className={styles[`${className}__learning`]}>
			<Typography
				fontVariant="medium"
				className={classNames(
					styles[`${className}__link`],
					styles[`${className}__learning_link`],
					{
						[styles[`${className}__link_active`]]: isActive,
					}
				)}
				onClick={() => setShow((p) => !p)}
			>
				{navTitle}
				{type === "mobile" && (
					<>{show ? <ArrowUpIcon /> : <ArrowDownIcon />}</>
				)}
			</Typography>
			{(type !== "mobile" || (type === "mobile" && show)) && (
				<div className={styles[`${className}__submenu`]}>
					{dropdownList?.length > 0
						? dropdownList?.map((item) => {
								return (
									<Typography
										key={item?.name}
										component={CustomLink}
										fontVariant="medium"
										to={item?.link}
										className={styles[`${className}__link`]}
										activeClassName={
											styles[`${className}__link_active`]
										}
										noactive
									>
										{item?.name}
									</Typography>
								)
						  })
						: null}
				</div>
			)}
		</div>
	)
}
export default DropdownNav
