import classNames from "classnames"
import parse from "html-react-parser"
import Typography from "../../base/Typography"
import styles from "./EditorView.module.sass"
import "./index.sass"
import { Children, Fragment, forwardRef, useEffect } from "react"

function prettifyHTML(node, level) {
	const indentBefore = new Array(level++ + 1).join("  ")
	const indentAfter = new Array(level - 1).join("  ")
	let textNode

	for (let i = 0; i < node.children.length; i++) {
		textNode = document.createTextNode("\n" + indentBefore)
		node.insertBefore(textNode, node.children[i])
		prettifyHTML(node.children[i], level)
		if (node.lastElementChild === node.children[i]) {
			textNode = document.createTextNode("\n" + indentAfter)
			node.appendChild(textNode)
		}
	}

	return node
}

export function printPrettyHTML(str) {
	const div = document.createElement("div")
	div.innerHTML = str.trim()
	return prettifyHTML(div, 0).innerHTML
}

const EditorView = forwardRef(({ content, className, ...props }, ref) => {
	if (!content) {
		return null
	}
	return (
		<Content className={className} {...props} ref={ref}>
			{parse(content)}
		</Content>
	)
})

export default EditorView

const Content = forwardRef(({ children, className, props }, ref) => {
	const childrenToArray = Children.toArray(children)
	useEffect(() => {
		if (ref?.current) {
			const images = ref.current?.querySelectorAll("img")

			if (images?.length) {
				for (let i = 0; i < images?.length; i++) {
					const span = document.createElement("span")
					span.classList.add("editor-image")
					const div = document.createElement("div")
					const width = images[i].getAttribute("width")
					const height = images[i].getAttribute("height")
					const reg = new RegExp(/^\d+$/)
					const wrapperWidth = ref.current?.offsetWidth
					if (reg.test(width)) {
						images[i].style.width =
							(+width * 100) / wrapperWidth + "%"
					}
					if (reg.test(height)) {
						images[i].style.minHeight =
							(+height * 100) / wrapperWidth + "%"
					}
					images[i].parentNode.insertBefore(span, images[i])
					div.appendChild(images[i])
					span.appendChild(div)
				}
			}
		}
	}, [ref])

	return (
		<Typography
			className={classNames(styles.wrapper, className)}
			fontVariant="regular"
			{...props}
			ref={ref}
		>
			{childrenToArray?.map((c, i) => {
				if (c?.type === "table") {
					return (
						<div
							className={styles.table__wrapper}
							key={c?.type + "-" + i}
						>
							{c}
						</div>
					)
				}
				return <Fragment key={c?.type + "-" + i}>{c}</Fragment>
			})}
		</Typography>
	)
})
