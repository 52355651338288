import React from "react"
import {
	createBrowserRouter,
	createRoutesFromElements,
	Route,
} from "react-router-dom"
import Page404, { Page404Layout } from "../404"
import Page500 from "../500"
import Catalog from "../Catalog/Catalog"
import Certificates from "../Certificates"
import Dasboard from "../Dashboard/Dashboard"
import Department from "../Department/Department"
import EmailSet from "../Email/EmailSet"
import FAQ from "../FAQ/FAQ"
import FinishCertificate from "../Finish/FinishCertificate"
import FinishReview from "../Finish/FinishReview"
import Homework from "../Homework/Homework"
import CompanyStructure from "../CompanyStructure/CompanyStructure"
import Document from "../KnowledgeBase/Document"
import KnowledgeBase from "../KnowledgeBase/KnowledgeBase"
import Lesson from "../Lesson/Lesson"
import LessonLayout from "../Lesson/layout/LessonLayout"
import Login from "../Login"
import Notifications from "../Notifications"
import NotificationsPopup from "../Notifications/NotificationsPopup"
import Onboarding from "../Onboarding/Onboarding"
import Recovery from "../Password/PasswordRecovery"
import Reset from "../Password/PasswordReset"
import Product from "../Product/Product"
import ProductLayout from "../Products/ProductLayout"
import Products from "../Products/Products"
import Profile from "../Profile/Profile"
import Quiz from "../Quiz/Quiz"
import Reviews from "../Reviews/Reviews"
import Survey from "../Survey"
import Surveys from "../Surveys/Surveys"
import AuthLayout from "../layouts/AuthLayout"
import { Layout } from "../layouts/Layout"
import { LayoutFullWidth } from "../layouts/LayoutFullWidth"
import SurveysLayout from "../layouts/SurveysLayout/SurveysLayout"
import ProtectedRoute from "./ProtectedRoute"
import ManualSurvey from "../ManualSurvey"
import Skills from "../Skills/Skills"
import AnonymousLayout from "../Anonymous/layout/Anonymous"
import ManualAnonymousSurvey from "../Anonymous/ManualSurvey"
import AnonymouseSurvey from "../Anonymous/Survey"
import AnonymousAuth from "../Anonymous/Auth/AnonymousAuth"
import FinishPage from "../Anonymous/FinfishPage"
import AllowedModulesGuard from "./AllowedModulesGuard"

const BasicRouter = createBrowserRouter(
	createRoutesFromElements(
		<Route element={<Page404Layout />}>
			<Route element={<AuthLayout />}>
				<Route path="/login" element={<Login />} />
				<Route path="/set-password" element={<Onboarding />} />
				<Route
					path="/onboarding"
					element={<Onboarding invite={true} />}
				/>
				<Route path="/forgot-password" element={<Recovery />} />
				<Route path="/reset-password" element={<Reset />} />
			</Route>
			<Route path="/set-email" element={<EmailSet />} />
			<Route path="*" element={<Page404 />} />
			<Route element={<ProtectedRoute component={Layout} />}>
				<Route path="/skills" element={<Skills />} />

				<Route path="/" element={<Dasboard />} />
				<Route path="/500" element={<Page500 />} />
				<Route element={<AllowedModulesGuard moduleId={[14, 15]} />}>
					<Route path="/surveys" element={<SurveysLayout />}>
						<Route index element={<Surveys />} />
						<Route
							path=":surveyId/questions/:questionId"
							element={<Survey />}
						/>
						<Route
							path=":surveyId/questions/:questionId/:surveyType"
							element={<ManualSurvey />}
						/>
						<Route
							path=":surveyId/questions/:questionId/:surveyType/people/:personId"
							element={<ManualSurvey />}
						/>
					</Route>
				</Route>
				<Route path="/certificates" element={<Certificates />} />
				{/* Learning */}
				<Route
					path="/catalog"
					element={<AllowedModulesGuard moduleId={13} />}
				>
					<Route index element={<Catalog />} />
				</Route>

				<Route
					path="/products"
					element={<AllowedModulesGuard moduleId={13} />}
				>
					<Route element={<ProductLayout />}>
						<Route index element={<Products />} />
						<Route path=":productId" element={<Product />} />
					</Route>
					<Route path=":productId/reviews" element={<Reviews />} />

					<Route element={<LessonLayout />}>
						<Route
							path=":productId/lesson/:postId"
							element={<Lesson />}
						/>
						<Route
							path=":productId/task/:postId"
							element={<Homework />}
						/>
						<Route
							path=":productId/quiz/:postId"
							element={<Quiz />}
						/>
					</Route>
				</Route>
				{/* Learning */}
				<Route path="/profile" element={<Profile />} />

				<Route path="/notifications" element={<Notifications />} />
				<Route
					path="/notifications-mobile"
					element={<NotificationsPopup />}
				/>

				<Route path="/support" element={<FAQ />} />

				<Route
					path="/knowledge-base"
					element={<AllowedModulesGuard moduleId={12} />}
				>
					<Route index element={<KnowledgeBase />} />
					<Route
						path="documents/:documentId"
						element={<Document />}
					/>
				</Route>
				<Route
					path="/company-structure/:departmentId"
					element={<Department />}
				/>
			</Route>
			<Route 
				element={
					<ProtectedRoute 
						component={LayoutFullWidth} 
					/>}>
				<Route
					path="/company-structure"
					element={<CompanyStructure />}
				/>
			</Route>
			{/* learning */}
			<Route element={<AllowedModulesGuard moduleId={13} />}>
				<Route
					path="/products/:productId/certificate"
					element={<FinishCertificate />}
				/>
				<Route
					path="/products/:productId/review"
					element={<FinishReview />}
				/>
			</Route>
			{/* learning */}
			<Route path="/anonymous/:token" element={<AnonymousAuth />}>
				<Route
					path="survey/:surveyId/question/:questionId"
					element={<AnonymousLayout />}
				>
					<Route index element={<AnonymouseSurvey />} />
					<Route
						path=":surveyType"
						element={<ManualAnonymousSurvey />}
					/>
					<Route
						path=":surveyType/:personId"
						element={<ManualAnonymousSurvey />}
					/>
				</Route>
			</Route>
			<Route path="/anonymous/survey/finish" element={<FinishPage />} />
		</Route>
	)
)

export default BasicRouter
